var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "out-layout",
    [
      _c(
        "header",
        {
          staticClass: "header my-3",
          attrs: { slot: "form-header" },
          slot: "form-header",
        },
        [
          _c("h2", { staticClass: "heading mb-3" }, [
            _vm._v("Esqueci a Senha"),
          ]),
          _c("p", { staticClass: "grey--text mb-2" }, [
            _vm._v(
              "\n      Digite seu e-mail no campo abaixo para receber as instruçoes para acessar sua conta.\n    "
            ),
          ]),
        ]
      ),
      _c(
        "template",
        { slot: "form-content" },
        [
          _c("RecoveryPasswordForm", {
            attrs: {
              errorMessage: _vm.recoveryErrorMessage,
              syncing: _vm.syncing,
            },
            on: { submit: _vm.sendInstructions },
          }),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "460" },
              model: {
                value: _vm.sentSuccess,
                callback: function ($$v) {
                  _vm.sentSuccess = $$v
                },
                expression: "sentSuccess",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "sent-success" },
                [
                  _c("v-card-text", [
                    _c("img", {
                      staticClass: "mb-3 icon",
                      attrs: {
                        src: require("@/assets/icon-mail-sent.svg"),
                        alt: "Email enviado",
                      },
                    }),
                    _c("h1", { staticClass: "mb-3 title" }, [
                      _vm._v("Solicitação para alterar senha foi aceita"),
                    ]),
                    _c("ul", { staticClass: "grey--text" }, [
                      _c("li", [_vm._v("Acesse seu e-mail")]),
                      _c("li", [_vm._v("Leia todas instruções")]),
                      _c("li", [
                        _vm._v("Abra o link e prossiga com segurança"),
                      ]),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { color: "primary", to: "/login" } },
                        [_vm._v("\n            Entendi\n          ")]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { color: "red", "multi-line": "" },
              model: {
                value: _vm.sentError,
                callback: function ($$v) {
                  _vm.sentError = $$v
                },
                expression: "sentError",
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.recoveryErrorMessage) + "\n      "
              ),
              _c(
                "v-btn",
                {
                  attrs: { flat: "" },
                  on: {
                    click: function ($event) {
                      _vm.sentError = false
                    },
                  },
                },
                [_vm._v("\n        Fechar\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "navigation" },
        [
          _c(
            "v-btn",
            { attrs: { color: "primary", to: "login", flat: "", small: "" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-account-circle"),
              ]),
              _vm._v("\n      Voltar o login\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }