<template>
  <out-layout>
    <header slot="form-header" class="header my-3">
      <h2 class="heading mb-3">Esqueci a Senha</h2>
      <p class="grey--text mb-2">
        Digite seu e-mail no campo abaixo para receber as instruçoes para acessar sua conta.
      </p>
    </header>

    <template slot="form-content">
      <RecoveryPasswordForm
        v-on:submit="sendInstructions"
        :errorMessage="recoveryErrorMessage"
        :syncing="syncing"
      />

      <v-dialog
        v-model="sentSuccess"
        persistent
        width="460"
      >
        <v-card class="sent-success">
          <v-card-text>
            <img class="mb-3 icon" src="@/assets/icon-mail-sent.svg" alt="Email enviado" />

            <h1 class="mb-3 title">Solicitação para alterar senha foi aceita</h1>

            <ul class="grey--text">
              <li>Acesse seu e-mail</li>
              <li>Leia todas instruções</li>
              <li>Abra o link e prossiga com segurança</li>
            </ul>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" :to="'/login'">
              Entendi
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="sentError"
        color="red"
        multi-line
      >
        {{ recoveryErrorMessage }}
        <v-btn
          flat
          @click="sentError = false"
        >
          Fechar
        </v-btn>
      </v-snackbar>
    </template>

    <template slot="navigation">
      <v-btn
        color="primary"
        :to="'login'"
        flat
        small
      >
        <v-icon left>mdi-account-circle</v-icon>
        Voltar o login
      </v-btn>
    </template>
  </out-layout>
</template>

<script>
import OutLayout from '@/layouts/OutLayout.vue';
import RecoveryPasswordForm from '@/components/auth/RecoveryPasswordForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'RecoveryPassword',
  components: {
    OutLayout,
    RecoveryPasswordForm,
  },
  data() {
    return {
      syncing: false,
      sentSuccess: false,
      sentError: false,
      recoveryErrorMessage: '',
    };
  },
  methods: {
    ...mapActions({
      recoveryPassword: 'auth/recoveryPassword',
    }),
    sendInstructions({ email }) {
      this.recoveryErrorMessage = '';
      this.syncing = true;

      this.recoveryPassword(email)
        .then(() => {
          this.sentSuccess = true;
          this.syncing = false;
        })
        .catch((err) => {
          this.sentError = true;
          this.recoveryErrorMessage = 'Aconteceu algo de errado! Tente novamente mais tarde.';
          this.syncing = false;
        });
    },
  },
};
</script>

<style lang="scss">
.sent-success {
  & {
    padding: 1rem 2rem;
  }
  .icon {
    display: block;
    margin: auto;
    max-width: 64px;
  }
}
</style>
