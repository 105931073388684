<template>
  <v-form class="px-4" @submit.prevent="" lazy-validation v-model="valid">
    <v-text-field
      label="E-mail"
      color="success"
      v-model.trim="formData.email"
      :rules="emailRules"
      :disabled="syncing"
      @keypress.enter="submit"
      required
    />

    <v-layout>
      <v-spacer />
      <v-btn
        color="success"
        :disabled="!valid && !errorMessage"
        :loading="syncing"
        @click="submit"
      >
        Enviar Instruções
      </v-btn>
      <v-spacer />
    </v-layout>
  </v-form>
</template>

<script>
import validationRules from '@/mixins/validation-rules';

export default {
  name: 'RecoveryPasswordForm',
  props: ['syncing', 'errorMessage'],
  data() {
    return {
      formData: {},
      emailRules: [validationRules.required, validationRules.email],
    };
  },
  methods: {
    submit() {
      this.$emit('submit', this.formData);
    },
  },
};
</script>

